import * as XLSX from 'xlsx';
import React, { useCallback, useEffect } from 'react';
import { Table, withForm, Button } from '@casanova/casanova-common';
import { TableBottom } from 'components';
import i18n from '@i18n';

import {
  NEW_CONTRACT,
  CONTRACT_DETAIL,
} from 'utils/roles/permissions/contractsPermissions';
import { validateRolePermissions } from 'utils/roles/index';

import columns from './columns';
import { config } from './config';

import { getListOfContractsService } from 'services/contracts';

function ListOfContracts({
  history,
  contracts,
  getContracts,
  getContractsFilters,
  setListOfContractsPage,
  resetListOfContractsParams,
  onUpdateForm,
  fetchCatalog,
}) {
  const { params, filters, searchPattern } = contracts;

  const handleAddClick = useCallback(() => {
    history.push('/contracts/add');
  }, [history.push]);

  const handleRowClick = useCallback(
    ({ uuid }) => {
      if (validateRolePermissions(CONTRACT_DETAIL)) {
        history.push(`/contracts/${uuid}/detail`);
      }
    },
    [history.push]
  );

  const changeTitles = (payload) =>
    payload.map((el) => ({
      Folio: el.folio,
      'No. Contrato': el.noContrato,
      'Fecha de incio': el.startDate,
      'Fecha fin': el.endDate,
      Cliente: el.juridicalPersonName,
      'Unidad de negocio': el.businessArea,
      'Monto Máximo': el.maxAmount,
      'Monto Mínimo': el.minAmount,
      'No. De Vehículos': el.includedVehicles,
      Sucursal: el.branch,
      Manager: el.manager,
      'Administrador de Flota': el.vehiclesAdmin,
      Coordinador: el.coordinator,
    }));

  const exportExcelInforation = async () => {
    const contentInformation = await getListOfContractsService({
      params: {
        size: 10000,
        property: 'id',
        direction: 'asc',
      },
    });
    const worksheet = XLSX.utils.json_to_sheet(
      changeTitles(contentInformation.content)
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Contratos virtuales');
    XLSX.writeFile(workbook, 'Contratos Virtuales.xlsx', { compression: true });
  };

  useEffect(() => {
    getContracts({ params, filters, searchPattern });
  }, [getContracts, params, filters, searchPattern]);

  useEffect(() => {
    resetListOfContractsParams();
    getContractsFilters();
  }, []);

  useEffect(() => {
    onUpdateForm(params);
  }, [onUpdateForm, params]);

  useEffect(() => {
    fetchCatalog({
      catalogPath: 'pricequotes',
      catalogId: 'catalogs/business-areas',
    });
  }, [fetchCatalog]);

  return (
    <section>
      <div className="col mb-3 d-flex align-items-center justify-content-end">
        <Button
          outline
          block
          className="mr-1 w-25 mt-0"
          onClick={() => exportExcelInforation()}
          id="containers_contracts_listOfContracts_excel"
        >
          Excel
        </Button>
      </div>
      <Table
        additionalHeader
        rowClick={handleRowClick}
        dataList={contracts.results}
        columns={columns}
      />
      <TableBottom
        search={contracts}
        onChange={setListOfContractsPage}
        onClick={handleAddClick}
        textButton={i18n('COMMONS__NEW')}
        id="containers_contracts_listOfContracts_newContractButton"
        actionPermission={NEW_CONTRACT}
      />
    </section>
  );
}

export default withForm({ config })(ListOfContracts);
